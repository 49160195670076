<template>
  <span v-if="position && myInfo?.id != user.id">
    <v-menu open-on-hover>
      <template v-slot:activator="{ props }">
        <UserCardS
          :user="user"
          :position="position"
          :host="host"
          v-bind="props"
        />
      </template>

      <v-list>
        <v-list-item
          v-on:click="remove(user.id)"
          v-if="position > user.position"
        >
          <v-list-item-title>移出工作室</v-list-item-title>
        </v-list-item>
        <span v-if="position > 1">
          <v-list-item
            v-on:click="setadmin(user.id, 0)"
            v-if="user.position == '1'"
          >
            <v-list-item-title>取消管理员</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="setadmin(user.id, 1)" v-else>
            <v-list-item-title>设为管理员</v-list-item-title>
          </v-list-item>
        </span>
        <span v-if="position > 2">
          <v-list-item
            v-on:click="setadmin(user.id, 0)"
            v-if="user.position == '2'"
          >
            <v-list-item-title>取消副室长</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="setadmin(user.id, 2)" v-else>
            <v-list-item-title>设为副室长</v-list-item-title>
          </v-list-item>
        </span>
        <v-list-item v-on:click="open('#page=user&id=' + user.id)">
          <v-list-item-title>主页</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
  <UserCardS :user="user" :position="position" :host="host" v-else />
</template>

<script setup>
import UserCardS from "./UsercardS.vue";
import { defineProps,defineEmits } from "vue";
const props = defineProps(["user", "host", "position", "myInfo", "studioId"]);
const emit=defineEmits(['userp'])
var open = (u) => window.open(u);
const setadmin = async (id, position) => {
  await window.post("studio/setadmin", {
    uid: id,
    id: props.studioId,
    p: position,
  })
  emit('userp')
};
const remove = async (id) => {
  if (!confirm("你确定要删除TA吗")) return;
  await window.post("studio/delete", {
    uid: id,
    id: props.studioId,
  });
  emit('userp')
};
</script>