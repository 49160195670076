<template>

    <v-card class="mb-3 sd">
        <v-card :href="'#page=work&id=' + work.id" elevation="0">
            <v-img :src="host.data + '/static/internalapi/asset/' + work.image" :aspect-ratio="4 / 3"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,0) calc( 100% - 26px ) , rgba(0, 0, 0, 0.6)" cover>
                <span class="ma-2">
                    <span>
                        <v-icon color="white" size="16">mdi-eye</v-icon>
                        <span style="font-size: 13px;color: rgb(255, 255, 255);"> {{ work.look }}</span>
                    </span>
                    <span class="ml-1">
                        <v-icon color="white" size="16">mdi-heart</v-icon>
                        <span style="font-size: 13px;color: rgb(255, 255, 255);"> {{ work.like }}</span>
                    </span>
                    <span class="ml-1">
                        <v-icon color="white" size="16">mdi-star</v-icon>
                        <span style="font-size: 13px;color: rgb(255, 255, 255);"> {{ work.num_collections }}</span>
                    </span>
                </span>
            </v-img>
            <v-row class="pt-5 pl-5 pb-5" style="max-width:100%" v-if="user">
                <span style="flex: 0 0 55px;max-width:55px">
                    <v-img width="45" :src="host.data + '/static/internalapi/asset/' + (user.head || '6e2b0b1056aaa08419fb69a3d7aa5727.png')"
                    aspect-ratio="1" class="rounded-circle">
                    </v-img>
                </span>
                <span style="flex: 0 0 calc( 100% - 55px );max-width:calc( 100% - 55px )">
                    <span  class="
                    text-h5 text-truncate text-caption pr-3
                    ">
                        <div class="row">
                            <div class="col-12 text-truncate ctext--text" style="font-size:15px;margin-bottom: -5px">
                                {{ work.name }}
                            </div>
                        </div>
                        <span class="text-truncate"></span>
                        <span :href="'#page=user&id=' + work.author" style="color:#888">{{ user.nickname }}</span>
                    </span>
                </span>
            </v-row>
            <v-row v-else>
                <div class="col-12 text-truncate ma-3 pa-3 ctext--text" style="font-size:15px;">
                    {{ work.name }}
                </div>
            </v-row>
        </v-card>
        <span v-if="my">
            <v-btn color="green" v-if="work.publish" block variant="text">已发布
            </v-btn>
            <v-btn color="red" v-else block variant="text">未发布
            </v-btn>
            <span>
                <v-btn  class="" :href="'/editor.html#id=' + work.id" target="_blank"  variant="text"
                    block>继续创作
                </v-btn>
                <v-btn  class="" v-on:click="del_work(work.id)" target="_blank" variant="text" block>删除
                </v-btn>
            </span>
            <br>
        </span>
    </v-card>

</template>
<script setup>
import { defineProps } from 'vue'
var props=defineProps(["work", "user", "host", "my"])
function del_work(){
    window.post("work/delete",{id:props.work.id})
}
</script>