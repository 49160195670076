<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        我的作品：
        <v-btn
          class="pa-2 text--secondary"
          elevation="0"
          v-on:click="new_work()"
          >新建作品</v-btn
        >
        <v-text-field
          v-model="searchKeyword"
          @change="fetchWorks"
          label="搜索作品"
          outlined
          dense
          hide-details
        ></v-text-field>
        <v-select
          v-model="publishStatus"
          :items="[
            { title: '全部', value: 'all' },
            { title: '发布', value: 'published' },
            { title: '未发布', value: 'unpublished' },
          ]"
          label="发布状态"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col v-if="!work"
        ><v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
        正在载入数据……
        </v-col>
      <v-col v-for="j in work" :key="j.id" cols="6" sm="4" md="3" lg="2">
        <s-workcard :work="j" :host="host" :my="true"></s-workcard>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="currentPage" :length="totalPages"></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
import SWorkcard from "./WorkCard.vue";
import { ref, watch, defineProps } from "vue";
defineProps(["host"]);

var work = ref(null);
var currentPage = ref(1);
var totalPages = ref(0);
var searchKeyword = ref("");
var publishStatus = ref("all");

const fetchWorks = () => {
  const limit = 12;
  const page = currentPage.value;
  const keyword = searchKeyword.value;
  const status = publishStatus.value;
  work.value = null;
  window
    .get(
      `work/my?page=${page}&limit=${limit}&keyword=${keyword}&publishStatus=${status}`
    )
    .then((response) => {
      work.value = response.data;
      totalPages.value = Math.ceil(response.total / limit); // Update total pages based on new response
    });
};
function new_work() {
  window
    .get("work/new")
    .then((data) => (location.href = "/editor.html#id=" + data.info.insertId));
}

watch([currentPage, publishStatus], () => {
  fetchWorks();
});

fetchWorks();
window.setTitle('我的作品')
</script>