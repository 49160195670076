<template>
    <v-container>
        <v-row>
            <v-col class="mx-auto" cols="12" md="6">
                <v-card class="mx-auto pa-8 my-12 sd">
                    <span color="accent">信息更改</span>
                    <v-text-field label="昵称" hide-myInfos="auto" class="my-2" id="i-input-0" v-model="nickname"
                        maxlength="30"></v-text-field>
                    <MarkdownInput name="input-1" label="个人介绍" v-model="introduce" hint="支持使用markdown编写" id="i-input-1"
                        maxlength="20000" counter />
                    <ImageUpload :host="host" v-model="image" name="头像"/>
                    <v-btn   class="pa-2 mx-auto sd" v-on:click="update()" block>更改信息</v-btn>
                    <br>
                    <v-divider></v-divider>
                    <br>
                    <span color="accent">安全设置</span>
                    <br>
                    <v-text-field v-model="password2" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[pw]"
                        :type="show1 ? 'text' : 'password'" name="input-2" label="旧密码" hide-myInfos="auto" counter
                        @click:append="show1 = !show1" id="a_opw"></v-text-field>
                    <v-text-field v-model="password3" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[pw]"
                        :type="show2 ? 'text' : 'password'" name="input-3" label="新密码" hide-myInfos="auto" counter
                        @click:append="show2 = !show2" id="a_npw"></v-text-field>
                    <v-btn   class="pa-2 mx-auto sd" v-on:click="changePW()" block>更改密码</v-btn>
                    <v-divider></v-divider>
                    <br>
                    <span style="color:#aaa">手机号：
                        <span style="padding-left:4px">{{ myInfo.phone }}</span>
                        <br>邮箱：
                        <span style="padding-left:4px">{{ myInfo.email }}</span>
                    </span>
                    <br>
                    <v-divider></v-divider>
                    <br>
                    <span color="accent">外观设置</span>
                    <v-switch v-model="newpage" label="在新窗口打开页面" color="primary" value="1" hide-details></v-switch>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup>

import { defineProps, ref, watch } from 'vue';
import MarkdownInput from './MarkdownInput.vue';
import ImageUpload from "./ImageUpload.vue";
var props = defineProps(['host', 'myInfo'])
var password2 = ref(''), password3 = ref(''), show1 = ref(false), show2 = ref(false),image=ref(props.myInfo.head)
var newpage = ref(window.localStorage.newpage), nickname = ref(props.myInfo?.nickname), introduce = ref(props.myInfo?.introduce)

watch(newpage, () => {
    window.localStorage.no_newpage = newpage.value===false
    window.$('#tg').attr('target',!newpage.value?'_self':'_blank')
})
function update() {
    window.post("user/info/update", {
        data: {
            image: image.value,
            nickname: nickname.value,
            introduce: introduce.value
        }
    })
}
function changePW(){
    window.get("user/change/password",{
        npw:password3.value,
        opw:password2.value
    })
}
window.setTitle('设置')
</script>
