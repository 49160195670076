<template>
    <v-app-bar app :color="theme ? '' : 'primary'" dark class="px-5" 
        style="box-shadow:0 2px 4px -1px rgb(0 0 0 / 5%),0 4px 5px 0 rgb(0 0 0 / 3%),0 1px 10px 0 rgb(0 0 0 / 3%)!important">
        <a href="#" style="color:#fff;font-size:20px" target="_self">40code</a>
        <span>
            <a href="#page=search&name=&author=&type=0&p=1&s=4" style="color:#fff;font-size:10px"
                class="ml-5">发现作品</a> </span>
        <span class="d-none d-sm-flex"><a href="#page=search&name=&author=&type=2&p=1&s=1"
                style="color:#fff;font-size:10px"  class="ml-5">工作室</a>
            <a href="#page=forum&sid=1" style="color:#fff;font-size:10px" class="ml-5">论坛</a>
            <a href="#page=post&id=180" style="color:#fff;font-size:10px" class="ml-5">赞助40code</a>
            <a href="#page=mywork" style="color:#fff;font-size:10px" class="ml-5">创作</a> <a
                href="#page=post&id=467" style="color:#fff;font-size:10px"
                class="ml-5">下载</a></span>
        <v-spacer></v-spacer>
        <v-btn icon @click="toggleTheme" color="white">
            <v-icon v-if="theme">mdi-weather-night</v-icon>
            <v-icon v-else>mdi-white-balance-sunny</v-icon>
        </v-btn>
        <span v-if="user">
            <v-btn icon href="#page=message" color="white">
                <span v-if="user && user.msgnum">
                    <v-badge color="red" :content="user.msgnum" overlap>
                        <v-icon>mdi-bell</v-icon>
                    </v-badge>
                </span>
                <v-icon v-else>mdi-bell</v-icon>
            </v-btn>
            <v-menu>
                <template v-slot:activator="{ props }">
                    <v-btn color="rgba(128,128,128,.06)" dark v-bind="props">
                        <v-img class="rounded-circle " width="30" aspect-ratio="1/1"
                            :src="host.data + '/static/internalapi/asset/' + (user.head || '6e2b0b1056aaa08419fb69a3d7aa5727.png')" />
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in items" :key="index" @click="item.c()">
                        <v-list-item-title>
                            {{ item.title }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </span>
        <v-btn color="secondary" v-else-if="user === undefined" class="sd" v-on:click="go()">
            <span style="color:#eee">
                登录/注册
            </span>
        </v-btn>
    </v-app-bar>

</template>

<script setup>
import { defineEmits, defineProps } from 'vue'
const props=defineProps(['user', 'host'])
const emit = defineEmits(['updateValue'])

function toggleTheme() {
    emit('updateTheme')
}

var go = () => {
    if (location.href.indexOf('page=sign') == -1)
        location.href = "#page=sign&url=" + btoa(location.href)
    else {
        location.href = "/#page=sign"
    }
}

var items = [{
    title: "我的主页",
    c: function () {
        location.href = "#page=user&id="+props.user.id
    }
}, {
    title: "我的作品",
    c: function () {
        location.href = "#page=mywork"
    }
}, {
    title: "我的工作室",
    c: function () {
        location.href = "#page=mystudio"
    }
}, {
    title: "我的物品",
    c: function () {
        location.href = "#page=myitem"
    }
}, {
    title: "账号设置",
    c: function () {
        location.href = "#page=setting"
    }
}, {
    title: "我的收藏",
    c: function () {
        location.href = "#page=sc"
    }
}, {
    title: "刷新",
    c: function () {
        emit('reload')
    }
}, {
    title: "退出登录",
    c: function () {
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.40code.com";
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        console.log("清除cookie"), location.href = ""
    }
}]
</script>