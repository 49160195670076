<template>
    <div>
        <v-card class="px-3">
            <v-card-title class="text-h5 grey lighten-2">举报</v-card-title>
            <v-card-subtitle  class="mt-2">
                举报内容:<a :href="options.href">{{ options.name }}</a>
            </v-card-subtitle>
            <MarkdownInput label="举报原因" v-model="fcontext" />
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="red" text @click="send" :disabled="sending">确认举报</v-btn>
              <v-btn color="#777" text @click="emit('success')">取消</v-btn>
            </v-card-actions>
          </v-card>
    </div>
</template>
<script setup>
import { defineProps,defineEmits,ref } from "vue";
import MarkdownInput from "./MarkdownInput.vue";
const sending=ref(0)
const props = defineProps(['options', 'success']);
const emit = defineEmits(['updateValue'])
var fcontext=ref('');


var send = () => {
  sending.value = 1;
  window.post("forum/new", {
    title: '[举报]'+props.options?.name,
    context: `[${props.options?.name}](${props.options?.href})<br>原因：`+fcontext.value,
    sid: 286
  }).then(() => {
    sending.value = 0;
    emit('success')
  });
};
</script>