<template>
    <div cols="12" id="comment-area" class="v-container">
        <v-card class="pa-5 sd">
            <span v-if="comment">
                <span class="text-h5 text--primary" cols="24">评论</span>
                <span style="color:#888" class="ml-3">{{ comment && (comment.num + ' 条评论') }}</span>
                <br>
                <br>
                <s-c2 :comment="comment" :host="host" :id="id" :type="type" :myInfo="myInfo" @reget="reget()"></s-c2>
                <s-comment :comment="comment" :host="host" :id="id" :type="type" :myInfo="myInfo"
                    @reget="reget()"></s-comment>
                <div class="text-center my-3" v-if="comment.num > 0">
                    <s-pagination v-model="page" :length="Math.ceil(comment.num / 6)">
                    </s-pagination>
                </div>
            </span>
            <span v-else>
                <v-progress-circular color="primary" indeterminate></v-progress-circular>
                评论正在加载中
            </span>
        </v-card>
    </div>
</template>
<script setup>
import { defineProps, ref, watch } from 'vue';
import SC2 from './CommentInput.vue';
import SComment from './SComment.vue';
import SPagination from './SPagination.vue';
var props = defineProps(['id', 'type', 'host', 'myInfo']),
    page = ref(1), comment = ref(null)


const reget = () => {
    comment.value = null
    window.get("comment/", {
        id: props.id,
        type: props.type,
        l: 6,
        o: 6 * (+page.value - 1)
    }).then(d => {
        let data = d.data
        for (let i in data.comment) {
            data.comment[i].time = window.convertTimestamp(data.comment[i].time)
        }
        for (let i in data.reply) {
            for (let j in data.reply[i]) {
                data.reply[i][j].time = window.convertTimestamp(data.reply[i][j].time)
            }
        }
        comment.value = data
    })
}

reget();
watch(page, () => {
    reget()
})

</script>