<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs :items="[
          {
            title: studio.name,
            href: '#page=studio&id=' + studio.id,
          },
          { title: '论坛', href: '#page=forum&sid=' + studio.id },
          { title: text.title },
        ]" divider=">"></v-breadcrumbs>
      </v-col>
      <v-col class="mx-auto">
        <v-card class="mx-auto pa-8 my-2 sd">
          <div class="d-flex align-center justify-space-between">
           
            <div class="text-h5">
              {{ text.title }}
              <span style="color: #aaa" class="pl-4">#{{ text.id }}</span>
            </div>
            <div>
              <v-btn class="text--secondary" text small
                v-if="myInfo && myInfo.id && (myInfo.id == author.id || myInfo.is_admin)" @click="openEditDialog">
                <v-icon>mdi-pencil</v-icon> 编辑
              </v-btn>
              <v-btn class="text--secondary ml-2" text small color="red"
                v-if="myInfo && myInfo.id && (myInfo.id == author.id || myInfo.is_admin)" @click="confirmDelete">
                <v-icon>mdi-delete</v-icon> 删除
              </v-btn>
            </div>
          </div>
          <br />
          <div>
            <a :href="'#page=user&id=' + author.id" class="text-h6">
              <v-avatar size="40" class="mr-2">
                <v-img cover :src="host.data +
                  '/static/internalapi/asset/' +
                  (author.head || '6e2b0b1056aaa08419fb69a3d7aa5727.png')
                  " /></v-avatar>{{ author.nickname }}
              <span style="color: #888; font-size: 13px" class="pl-2">{{
                date(text.new_time)
              }}</span></a>
          </div>
          <br />
          <div>
            <MarkdownContext v-if="text.context" :text="text.context" />
            <br><v-divider></v-divider>
            <s-item :host="host" :myInfo="myInfo" :page="3" :id="id"></s-item>
            <span v-if="text.tags"><v-chip :color="j.color" variant="flat" class="mr-1" v-for="j in (text.tags && JSON.parse(text.tags))" :key="j">{{j.text}}</v-chip></span>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12">
        <CommentArea :id="id" :host="host" :type="3" :myInfo="myInfo"></CommentArea>
      </v-col>
    </v-row>

    <!-- Edit Post Dialog -->
    <v-dialog v-model="editDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">编辑帖子</span>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="editPost.title" label="标题" outlined></v-text-field>
          <v-textarea v-model="editPost.context" label="内容" outlined rows="10"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDialog = false">取消</v-btn>
          <v-btn color="blue darken-1" text @click="savePost">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm Delete Dialog -->
    <v-dialog v-model="deleteDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">确认删除</span>
        </v-card-title>
        <v-card-text>
          你确定要删除这个帖子吗？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteDialog = false">取消</v-btn>
          <v-btn color="red" text @click="del_post">删除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
import { ref, defineProps } from 'vue';
import MarkdownContext from './MarkdownContext.vue';
import CommentArea from './CommentArea.vue';
import SItem from './SItem.vue';
defineProps(['host', 'myInfo']);
const text = ref({});
const author = ref({});
const studio = ref({});
const id = ref(window.getQueryString('id'));

const editDialog = ref(false);
const deleteDialog = ref(false);

const editPost = ref({
  title: '',
  context: ''
});

const get = () => {
  window.get('forum/post', { id: id.value })
    .then(data => {
      data = data.data;
      text.value = data.text;
      author.value = data.author;
      studio.value = data.studio;
      editPost.value.title = data.text.title;
      editPost.value.context = data.text.context;
      window.setTitle(data.text.title);
    })
    .catch(error => {
      console.error('获取帖子失败:', error);
    });
};
get();

const confirmDelete = () => {
  deleteDialog.value = true;
};

const del_post = () => {
  window.post('forum/delete', { id: id.value })
    .then(() => location.href = '#')
    .catch(error => {
      console.error('删除帖子失败:', error);
    });
};

const openEditDialog = () => {
  editDialog.value = true;
};

const savePost = () => {
  window.post('forum/update', { id: id.value, ...editPost.value })
    .then(() => {
      editDialog.value = false;
      get(); // Refresh post details after saving
    })
    .catch(error => {
      console.error('修改帖子失败:', error);
    });
};

const date = window.convertTimestamp;
</script>

<style scoped>
/* Style for buttons and title container */
.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-space-between {
  justify-content: space-between;
}

.ml-2 {
  margin-left: 8px;
}
</style>