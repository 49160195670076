<!-- <link rel="stylesheet" href="./css/main.css" /> -->
<template>
  <v-app :theme="theme" color="background">
    <v-main>
      <AppBar @updateTheme="updateTheme()" @reload="reload2()" :user="myInfo" :host="host" />
      <span v-if="rl">
        <Home v-if="page == null" :host="host" />
        <Sign v-else-if="page == 'sign'" :host="host" />
        <User v-else-if="page == 'user'" :host="host" :myInfo="myInfo" @reload="reload2()" />
        <s-message v-else-if="page == 'message'" :host="host" :myInfo="myInfo" />
        <span v-else-if="page == 'setting'">
          <s-setting v-if="myInfo" :host="host" :myInfo="myInfo" />
          <Empty v-else />
        </span>
        <s-work v-else-if="page == 'work'" :host="host" :myInfo="myInfo" />
        <s-search v-else-if="page == 'search'" :host="host" :myInfo="myInfo" />
        <my-work v-else-if="page == 'mywork'" :host="host" :myInfo="myInfo" />
        <s-forum v-else-if="page == 'forum'" :host="host" :myInfo="myInfo" />
        <s-post v-else-if="page == 'post'" :host="host" :myInfo="myInfo" />
        <s-studio v-else-if="page == 'studio'" :host="host" :myInfo="myInfo" />
        <span v-else-if="page == 'myitem'">
          <my-item v-if="myInfo" :host="host" :myInfo="myInfo" />
          <Empty v-else />
        </span>
        <span v-else-if="page == 'mystudio'">
          <my-studio v-if="myInfo" :host="host" :myInfo="myInfo" />
          <Empty v-else />
        </span>
        <studio-edit v-else-if="page == 'studio_edit'" :host="host" :myInfo="myInfo" />
        <work-info v-else-if="page == 'workinfo'" :host="host" :myInfo="myInfo" />
        <collection-management v-else-if="page == 'sc'" :host="host" :myInfo="myInfo" />
        <upload-ext v-else-if="page == 'ue'" :host="host" :myInfo="myInfo" />
        <b-c-m v-else-if="page == 'bcm'" :host="host" :myInfo="myInfo" />
        <Empty v-else />
      </span>
    </v-main>
    <v-footer style="background-color:#0000" class="pt-9 mb-n4">
      <v-col cols="12" class="px-5">
        <span>
          <div class="float-left">
            <span class="ctext--text">友情链接：</span>
            <br />
            <a href="https://www.rainyun.com/MzEzMDc=_" target="_blank" class="ctext--text">雨云(可以买服务器，价格便宜)</a>
            <br />
          </div>
          <div class="float-left ml-5">
            <a href="https://packager.40code.com/" target="_blank" class="ctext--text">将scratch作品打包成exe或html</a>
            <br />
            <a href="https://tools.40code.com/srcw" target="_blank" class="ctext--text">scratch项目源代码比较器</a>
            <br />
            <a href="https://jq.qq.com/?_wv=1027&k=7X0G5yel" target="_blank" class="ctext--text">Q群:1071652975</a>
            <br />
            <a href="https://github.com/40codeTeam" target="_blank" class="ctext--text">源代码</a>
            <br />
          </div>
        </span>
        <span class="float-right ctext--text">{{ new Date().getFullYear() }} — <strong>40CODE</strong></span>
      </v-col>
    </v-footer>
    <Dialog @close="closeDialog" :list="dialogList" :host="host"></Dialog>
    <Alert @close="closeAlert" :list="alertList"></Alert>
  </v-app>
</template>
<script>
import AppBar from "./components/AppBar.vue";
import Dialog from "./components/SDialog.vue";
import Alert from "./components/SAlert.vue";
import Sign from "./components/SSign.vue";
import Empty from "./components/SEmpty.vue";
import Home from "./components/SHome.vue";
import User from "./components/SUser.vue";
import SMessage from "./components/SMessage.vue";
import SSetting from "./components/SSetting.vue";
import SWork from "./components/SWork.vue";
import SSearch from "./components/SSearch.vue";
import { ref, provide } from "vue";
import MyWork from "./components/MyWork.vue";
import SForum from "./components/SForum.vue";
import SPost from "./components/SPost.vue";
import MyItem from "./components/MyItem.vue";
import MyStudio from "./components/MyStudio.vue";
import SStudio from "./components/SStudio.vue";
import StudioEdit from "./components/StudioEdit.vue";
import WorkInfo from "./components/WorkInfo.vue";
import CollectionManagement from "./components/CollectionManagement.vue";
import UploadExt from "./components/UploadExt.vue";
import BCM from "./components/BCM.vue";
import "./assets/css/main.css";

var page,
  oldpage = window.getQueryString("page") || "index";
export default {
  name: "App",

  components: {
    AppBar,
    Dialog,
    Alert,
    Sign,
    Empty,
    Home,
    User,
    SMessage,
    SSetting,
    SWork,
    SSearch,
    MyWork,
    SForum,
    SPost,
    MyItem,
    MyStudio,
    SStudio,
    StudioEdit,
    WorkInfo,
    CollectionManagement,
    UploadExt,
    BCM,
  },

  methods: {
    handleHashChange() {
      console.log(oldpage);
      for (let i of ["search"])
        if ((!oldpage || oldpage == i) && window.getQueryString("page") == i)
          return;
      window.reload2();
    },
  },

  created() {
    window.addEventListener("hashchange", this.handleHashChange);
  },

  setup() {
    const theme = ref(localStorage.theme || "light");
    var myInfo = ref(null);

    const host = ref({
      data: window.mianhost,
      scratch: window.scratchhost,
    });
    const dialogList = ref([]);
    const alertList = ref([]);
    page = ref(window.getQueryString("page"));
    // oldpage = page.value;
    var rl = ref(true);
    const reload2 = () => {
      rl.value = false;
      // rl.value = true;
      // console.log(1)
      setTimeout(() => {
        oldpage = window.getQueryString("page") || "index";
        page.value = window.getQueryString("page");
        getMyInfo();
        rl.value = true;
      }, 50);
    };
    window.reload2 = reload2;
    const updateTheme = () => {
      localStorage.theme = theme.value =
        theme.value == "dark" ? "light" : "dark";
    };

    const closeDialog = (res) => {
      // console.log(id)
      if (!dialogList.value.length) return;
      let delay = dialogList.value.length > 1 ? 100 : 200;
      dialogList.value[0].hide = true;
      setTimeout(() => {
        let callback = dialogList.value[0].callback;
        typeof callback == "function" && callback(res);
        dialogList.value.shift();
      }, delay);
    };

    var dialogCounts = 0;
    const dialog = (text, options, callback) => {
      if (callback && typeof callback != "function") {
        return new Promise((resolve) => {
          dialogList.value.push({
            text,
            options,
            callback: (res) => {
              resolve(res);
            },
          });
        });
      }
      dialogList.value.push({ text, options, callback, id: dialogCounts });
      dialogCounts++;
    };

    const calculateTimeout = () => {
      let s = 0;
      console.log(alertList.value);
      for (let i = 0; i < alertList.value.length; i++) {
        s += alertList.value[i].timeout;
        let num = alertList.value[i].num;
        setTimeout(() => {
          alertList.value.map((item) => {
            if (item.num == num) {
              item.hide = true;
            }
            return item;
          });
          setTimeout(() => {
            alertList.value = alertList.value.filter((item) => item.num != num);
          }, 100);
        }, s - 100);
      }
    };
    const closeAlert = () => {
      alertList.value[0].hide = true;
      setTimeout(() => {
        alertList.value.shift();
        calculateTimeout();
      }, 100);
    };
    var alertnum = 0;
    const alert = (text, timeout = 2000) => {
      let num = alertnum;
      alertnum++;
      alertList.value.push({ text, timeout, num });
      calculateTimeout();
    };

    const getMyInfo = () => {
      window.get("user/myinfo").then((data) => {
        myInfo.value = data.data;
      });
    };
    getMyInfo();

    provide("dialog", dialog);
    provide("alert", alert);
    window.dialog = dialog;
    window.alert = alert;

    window
      .$("#tg")
      .attr(
        "target",
        window.localStorage.no_newpage !== false ? "_self" : "_blank"
      );

    return {
      theme,
      myInfo,
      host,
      dialogList,
      alertList,
      updateTheme,
      closeDialog,
      closeAlert,
      page,
      rl,
      reload2,
    };
  },
};
</script>
