<template>
  <v-container>
    <v-row v-if="info">
      <v-col cols="12">
        <v-card class="pa-5 sd">
          <v-alert dense outlined type="error" v-if="info.ban">
            当前工作室已被封禁，请联系
            <a href="#page=studio&id=286">管理员</a>进行申诉
          </v-alert>

          <v-row align="center">
            <v-col cols="auto">
              <v-avatar size="120">
                <v-img
                  cover
                  :src="`${host.data}/static/internalapi/asset/${info.head || '6e2b0b1056aaa08419fb69a3d7aa5727.png'}`"
                />
              </v-avatar>
            </v-col>

            <v-col>
              <span class="text-h5">{{ info.name }}</span>
              <div class="button-group">
                <v-btn v-if="myInfo && myInfo.id && p > 0" :href="`#page=studio_edit&id=${info.id}`" class="sd">编辑信息</v-btn>
                <v-btn v-if="p===undefined" @click="join" class="sd">加入</v-btn>
                <v-btn v-if="[0,1,2].includes(p)" @click="quit" class="sd">退出</v-btn>
                <v-btn v-if="p!==undefined" @click="main" class="sd">设为主室</v-btn>
                <v-btn v-if="p!==undefined" @click="upload" class="sd">投稿作品</v-btn>
                <v-btn :href="`#page=forum&sid=${info.id}`" class="sd">论坛</v-btn>
              </div>
              <div class="text-subtitle-1 text--disabled">创建时间：{{ date(info.created_time) }}</div>
            </v-col>
          </v-row>

          <Item
            v-if="info"
            :host="host"
            :myInfo="myInfo"
            page="studio"
            :id="id"
          />
        </v-card>
      </v-col>

      <v-col cols="12" v-if="info">
        <v-card class="pa-5 sd">
          <v-row align="center" class="mb-4">
            <v-col>
              <span class="text-h5 text--primary">工作室介绍</span>
            </v-col>
          </v-row>
          <MarkdownContext class="text--secondary pm" :text="info.introduce" />
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-row align="center" class="mb-4">
          <v-col>
            <div>工作室作品：</div>
          </v-col>
          <v-col class="text-right">
            <a :href="`#page=search&name=&author=&type=0&p=1&s=0&sid=${info.id}`" class="text-none ">
              <v-btn text class="tg sd">更多</v-btn>
            </a>
          </v-col>
        </v-row>
        <v-row v-if="worklist" cols="12">
          <v-col v-for="work in worklist" :key="work.id" cols="6" sm="4" md="3" lg="2">
            <s-workcard :work="work" :host="host" :user="userlist2[work.author][0]" />
          </v-col>
        </v-row>
        <span v-else>当前工作室无作品</span>
      </v-col>

      <v-col cols="12">
        <v-row align="center" class="mb-4">
          <v-col>
            <div>工作室成员：</div>
          </v-col>
          <v-col class="text-right">
            <a :href="`#page=search&name=&author=&type=1&p=1&s=0&sid=${info.id}`" class="text-none">
              <v-btn text class=" tg sd">更多</v-btn>
            </a>
          </v-col>
        </v-row>
        <v-row v-if="userlist" cols="12">
          <v-col v-for="user in userlist" :key="user.id" cols="6" sm="4" md="3" lg="2">
            <s-usercard
              :user="user"
              :host="host"
              :position="p"
              :o="studio"
              :myInfo="myInfo"
              :studioId="id"
              @userp="getuser"
            />
          </v-col>
        </v-row>
        <span v-else>当前工作室无成员</span>
      </v-col>

      <CommentArea
        :id="id"
        :host="host"
        :type="2"
        :myInfo="myInfo"
      />
    </v-row>

    <span v-else>
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
      正在载入数据……
    </span>
  </v-container>
</template>

<script setup>
import { ref, onMounted,defineProps } from 'vue';
import MarkdownContext from './MarkdownContext.vue';
import CommentArea from './CommentArea.vue';
import SWorkcard from './WorkCard.vue';
import SUsercard from './UserCard.vue';
import Item from './SItem.vue';

defineProps(['host', 'myInfo']);
const userlist = ref([]);
const p = ref();
const worklist = ref([]);
const userlist2 = ref([]);
const info = ref(null);
const id = ref(window.getQueryString('id'));

const date = window.convertTimestamp;
const get = window.get;
const post = window.post;

const getwork = async () => {
  try {
    const data = await get('studio/work', { id: id.value });
    worklist.value = data.data;
    userlist2.value = data.userlist;
  } catch (error) {
    console.error(error);
  }
};

const getuser = async () => {
  try {
    const data = await get('studio/user', { id: id.value });
    userlist.value = data.data;
    p.value = data.p;
  } catch (error) {
    console.error(error);
  }
};

const fetchInfo = async () => {
  try {
    const data = await get('studio/info', { id: id.value });
    info.value = data.data;
    window.setTitle(info.value.name);
  } catch (error) {
    console.error(error);
  }
};

const quit = async () => {
  if (!confirm('你确定要退出吗')) return;
  try {
    await post('studio/quit', { id: info.value.id });
    window.reload2();
  } catch (error) {
    console.error(error);
  }
};

const join = async () => {
  if (info.value.chose === 2) {
    return alert('此工作室禁止任何人加入');
  }
  try {
    const password = info.value.haspw && prompt('请输入工作室加入密码');
    await post('studio/join', { pw: password, id: info.value.id });
    window.reload2();
  } catch (error) {
    console.error(error);
  }
};

const main = async () => {
  try {
    await post('studio/setmain', { id: info.value.id });
    window.reload2();
  } catch (error) {
    console.error(error);
  }
};

const upload = async () => {
  let e;
  let t = prompt('请输入作品的链接或id');
  if (isNaN(parseInt(t, 10))) {
    if (t.indexOf('#') < 0) return alert('请输入正确的链接');
    let n = new RegExp('(^|&)id=([^&]*)(&|$)', 'i');
    const match = t.split('#')[1].match(n);
    if (match && match[2]) {
      e = match[2];
    } else {
      return alert('请输入正确的链接');
    }
  } else {
    e = t;
  }
  try {
    await post('studio/upload', { id: info.value.id, work: e });
    window.reload2();
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  fetchInfo();
  getwork();
  getuser();
  window.setTitle('工作室');
});
</script>
<style scoped>
.sd {
  margin: 0;
}

.button-group {
  margin-top: 16px;
}

.button-group .v-btn {
  margin-right: 8px;
}


</style>
