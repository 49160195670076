<template>
  <v-container>
    <v-row>
      <v-col class="" cols="12"
        >我的工作室：
        <v-btn
          class="pa-2 text--secondary"
          elevation="0"
          v-on:click="new_studio()"
          >新建工作室</v-btn
        ></v-col
      >
      <v-col v-if="!my"
        ><v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
        正在载入数据……
        </v-col>
      <v-col v-for="j in my" :key="j.id" cols="12" sm="6" md="4" lg="3">
        <s-studiocard :studio="j" :host="host"></s-studiocard>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
import { ref, defineProps } from "vue";
import SStudiocard from "@/components/StudioCard.vue";
defineProps(["host", "myInfo"]);
var my = ref(null);
window.get("studio/my").then((data) => (my.value = data.data));
window.setTitle('我的工作室')
function new_studio(){
  if(!confirm("你确定要创建工作室吗？")) return
  window.get("studio/new").then(e=>location.href = "/#page=studio&id=" + e.id)
}
</script>