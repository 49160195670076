<template>
    <v-dialog v-model="dialog2" max-width="600" min-height="300">
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon="mdi-close" dark @click="$emit('close1')">
                </v-btn>
                <v-toolbar-title>使用
                    - {{ items[id2] && (items[id2].name || items[id2].item && items[id2].item.name) }}</v-toolbar-title>
            </v-toolbar>
            <v-list-item>
                <span v-html="tl[id] ? tl[id]?.t : '确认使用' + (items[id2] && (items[id2].name || items[id2].item && items[id2].item.name)) + '?'"></span>
            </v-list-item>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  class="my-2 sd" @click="tuse()">使用</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { defineProps, ref, defineEmits, watch } from 'vue';
var dialog2 = ref(true);
watch(dialog2, (value) => {
    if (!value) emit('close1')
})
var tl = window.tl
const props = defineProps(['host', 'id', 'items', 'myInfo', 'page', 'toid'])
var id2 = -1;

for (let i in props.items) {
    if (props.items[i].itemId == props.id) {
        id2 = i;
        break;
    }
}
// console.log(props.id, id2, props.items);
const emit = defineEmits(['updateValue'])
let t = window.t
const tuse = () => {
    window.post("item/use", {
        itemId: props.id,
        useid: props.toid,
        useto: t[props.page] || props.page,
        options: tl[props.id] && tl[props.id].f && eval("(" + tl[props.id].f + ")")
    }).then(()=>window.reload2())
}
</script>