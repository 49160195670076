import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()
const app = createApp(App)
// console.log(app)

console.log(vuetify)

app
// .use(store)
.use(vuetify)
  .mount('#app')
// console.log(app)
