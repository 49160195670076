<template>
    <v-list dense>
        <v-list-item v-for="folder in folders" :key="folder.id" @click="selectFolder(folder.id)"
            :style="selectedFolderId == folder.id ? 'background-color:#1976d2;color:white' : null">
            <v-list-item-title>{{ folder.name }}</v-list-item-title>
            <v-list-item-subtitle v-if="folder.is_private">（仅自己可见）</v-list-item-subtitle>
        </v-list-item>
    </v-list>
    <v-row>
        <v-col cols="6">
            <v-btn block @click="createFolder" color="primary">新建收藏夹</v-btn>
        </v-col>
        <v-col cols="6">
            <v-btn block v-if="selectedFolderId === null" disabled>
                请先选择收藏夹
            </v-btn>
            <v-btn block v-else @click="addWork" color="primary" v-bind:disabled="selectedFolderId === null">
                确认收藏
            </v-btn>
        </v-col>
    </v-row>
</template>
<script setup>
import { defineProps, ref, defineEmits } from 'vue';
const props = defineProps(['host', 'id', 'success']);
const emit = defineEmits(['updateValue']);
var folders = ref([]),
    selectedFolderId = ref(null),
    selectedFolderName = ref(null)
var get = window.get, post = window.post
get('/collections/folders')
    .then(d => (folders.value = d.folders))

var selectFolder = (folderId) => {
    console.log(folderId,this)
    selectedFolderId.value = folderId;
    selectedFolderName.value = folders.value.find(folder => folder.id === folderId).name;
};
var createFolder = () => {
    const folderName = prompt('请输入收藏夹的名称');
    if (!folderName) return
    post('/collections/folders', { name: folderName })
        .then(d => {
            folders.value = d.folders
            selectFolder(folders.value[folders.value.length - 1].id);
        }).catch(() => {
            window.alert('新建收藏夹失败')
        })
}; // 添加作品
var addWork = () => {
    if (selectedFolderId.value === null) return;
    post('/collections/collections', {
        work_id: props.id,
        folder_id: selectedFolderId.value,
    }).then(() => {
        alert('添加成功')
        emit('success')
    }).catch(() => {

    })
}
</script>