<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="7" class="mx-auto">
        <span v-if="!info">请等待 正在加载中</span>
        <v-card class="pa-8 my-12" v-else>
          <a :href="`#page=studio&id=${id}`">{{ name }}</a>
          <v-text-field
            label="工作室名"
            hide-details="auto"
            class="my-2"
            v-model="info.name"
            maxlength="30"
          ></v-text-field>
          <MarkdownInput
            label="工作室介绍"
            hide-details="auto"
            class="my-2"
            v-model="info.introduce"
            maxlength="20000"
          />
          <v-text-field
            label="在工作室论坛内发帖子时的提示"
            hide-details="auto"
            class="my-2"
            v-model="info.notes"
            maxlength="30"
          ></v-text-field>
          <v-checkbox
            v-model="info.haspw"
            label="启用密码"
          ></v-checkbox>
          <v-text-field
            label="加入密码"
            hide-details="auto"
            class="mx-3"
            v-model="info.pw"
            v-if="info.haspw"
            width="100px"
          ></v-text-field>
          <v-radio-group v-model="info.chose" mandatory>
            <v-radio
              :label="`允许${info.haspw ? '输对密码的' : ''}所有人加入`"
              :value="0"
            ></v-radio>
            <v-radio
              :label="`允许${info.haspw ? '输对密码并' : ''}没有工作室的人加入`"
              :value="1"
            ></v-radio>
            <v-radio label="禁止任何人加入" :value="2"></v-radio>
          </v-radio-group>
          
          <!-- 新增的选项 -->
          <v-checkbox
            v-model="info.postPermission"
            label="仅允许成员发帖"
          ></v-checkbox>
          <v-checkbox
            v-model="info.viewPermission"
            label="仅允许成员查看"
            class="my-n3 pb-6"
          ></v-checkbox>

          <v-text-field
            label="工作室头衔颜色"
            hide-details="auto"
            class="mx-3"
            v-model="info.color"
          ></v-text-field>
          <v-color-picker class="mx-auto mt-2" v-model="info.color" show-swatches></v-color-picker>
          
          <ImageUpload :host="host" v-model="info.head" class="mt-4"/>
          
          <div class="mx-auto">
            <v-btn color="accent" class="pa-2" v-on:click="update()"
              >更新</v-btn
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, defineProps } from "vue";
import MarkdownInput from './MarkdownInput.vue';
import ImageUpload from "./ImageUpload.vue";
defineProps(["host"]);
var id = ref(window.getQueryString("id"))
var info = ref({});
window
  .get("studio/info", {
    id: id.value,
  })
  .then((data) => {
    info.value = data.data;
    info.value.haspw = !!info.value.haspw;
    info.value.color = info.value.color || "#388E3C";
    // 初始化新增的选项
    info.value.postPermission = !!info.value.postPermission;
    info.value.viewPermission = !!info.value.viewPermission;
  });
var update = function () {
  window.post("studio/info/update", info.value);
};

window.setTitle('工作室设置')
</script>