<template>
    <v-pagination v-if="length>1" v-model="page" :length="length" :total-visible="tv" id="vpagination" rounded :size="size">
    </v-pagination>
</template>
<script setup>
import { defineModel,defineProps,ref,onUnmounted } from 'vue';
const page = defineModel({ default: 0 })
defineProps(['length'])
var tv=ref(5),size=ref('default')
var intervalId=setInterval(() => {
    var $=window.$
    let w=$('#vpagination')[0]?.parentNode?.offsetWidth;
    // console.log(w,tv.value)
    if(w<530){
        if(w<400)
        tv.value=3
        else
        tv.value=4
        size.value='small'
    }else{
        if(w<700) tv.value=5
        else tv.value=7
        size.value='default'
    } 
}, 100);
onUnmounted(()=>clearInterval(intervalId))
</script>